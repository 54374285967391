// @ts-strict-ignore
import { useRouter } from 'next/router'
import NewBasicContainer from '../container/NewBasicContainer'

const LandingLayoutContainer = (props) => {
  const router = useRouter()
  const isApp = router.query.app

  return (
    <NewBasicContainer talk={isApp ? false : true} {...props}>
      <div>{props.children}</div>
    </NewBasicContainer>
  )
}

export default LandingLayoutContainer
