// @ts-strict-ignore

import { GetServerSideProps } from 'next'
import cookies from 'next-cookies'
import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import Footer from '../../../../components/common/Footer'
import LandingLayoutContainer from '../../../../components/landing-legacy/LandingLayoutContainer'
import { InitialProps } from '../../../../model/Common/common.interface'
import apis from '../../../../modules/apis'
import { axiosGet } from '../../../../modules/axiosHelper'
import { useCurrentUser } from '../../../../modules/context/UserContext'
import { LOCALE_KO } from '../../../../modules/i18n/config'
import { TUTOR_LANDING_HOME_SEO_IMAGE } from '../../../../modules/images'
import { getOgUrl } from '../../../../modules/utils'
import { COOKIE_LIST, COOKIE_OPT, SESSION_ROLE } from '../../../../modules/vars'

const TutorLandingHomeContents = dynamic(
  () => import('./../../../../components/tutor/landing/TutorLandingHomeContents')
)

const Home = ({ json, ogData }: InitialProps) => {
  const { currentUser } = useCurrentUser()
  const [cookie, setCookie] = useCookies(['friend'])

  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const friend = params.get('friend')
    if (friend) {
      setCookie('friend', friend, COOKIE_OPT)
    }
  }, [])

  return (
    <LandingLayoutContainer tutor currentUser={currentUser} ogData={ogData}>
      <TutorLandingHomeContents currentUser={currentUser} result={json?.result} />
      <div className="ringle-tutor-repo"></div>
      <Footer tutor showMenu dark />
    </LandingLayoutContainer>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { jwt_token, session_role } = context.req.cookies
  const { lang = LOCALE_KO } = context.params

  let { friend } = cookies(context)
  let json = null

  if (!friend) {
    //FIXME :  context.query.friend가 array 일 경우에 대한 예외처리
    friend = typeof context.query.friend == 'string' ? friend : ''
  }

  // Remove Token & Session Role is session-role is not tutor
  if (jwt_token && session_role !== SESSION_ROLE.TUTOR) {
    context.res.setHeader('Set-Cookie', [
      `${COOKIE_LIST.JWT_TOKEN}=deleted; Domain=${COOKIE_OPT.domain}; Path=${COOKIE_OPT.path}; Max-Age=0;`,
      `${COOKIE_LIST.SESSION_ROLE}=deleted; Domain=${COOKIE_OPT.domain}; Path=${COOKIE_OPT.path}; Max-Age=0;`,
    ])
  } else if (jwt_token && session_role === SESSION_ROLE.TUTOR) {
    json = await axiosGet(apis.referral(friend), { initialToken: jwt_token })
  }

  const ogData = {
    title: 'Become a Tutor Now and Earn $17-22 per hour!',
    desc: 'Flexible, Fully-remote, No Minimum Working Hours. Perfect part-time job for your semester.',
    image: TUTOR_LANDING_HOME_SEO_IMAGE,
    ogUrl: getOgUrl({ lang, context: { pathname: context.resolvedUrl } }),
  }

  return {
    props: { json, ogData },
  }
}

export default Home
